<template>
<div class="container">
    <div>
        <el-breadcrumb separator=">">
            <el-breadcrumb-item>
                <span class="ant-breadcrumb-link">
                    <i class="iconfont ec-icon-location-outline"></i>&nbsp;当前位置
                </span>
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/report/list' }">研报通</el-breadcrumb-item>
            <el-breadcrumb-item>{{reportInfo?.title}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
        <div class="ant-row">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-image class="img-report-detail" :src="reportInfo?.pic" fit="fill">
                        <template #error>
                        <div class="image-slot">
                            <i class="iconfont ec-icon-image"></i>
                        </div>
                        </template>
                    </el-image>
                </el-col>
                <el-col :span="18">
                        <h2 >{{reportInfo?.title}}</h2>
                        <div class="info-attr">
                            <span><i class="iconfont ec-icon-time"></i>&nbsp;{{parseTime(new Date(reportInfo?.date),'{y}-{m}-{d}')}}</span>
                            <el-divider direction="vertical" />
                            <!-- <span><i class="iconfont ec-icon-view"></i>&nbsp;{{reportInfo?.viewCount}}</span>
                            <el-divider direction="vertical" /> -->
                            <span><i class="iconfont ec-icon-page"></i>&nbsp;{{reportInfo?.pageCount}}</span>
                        </div>     
                        <div class="info-desc">
                            <span>报告类型：{{reportInfo?.style==1?'行业报告':'地区报告'}}</span>
                            <el-divider border-style="dashed" style="margin:10px 0;" />
                            <span>报告形式：{{reportInfo?.documentType}}文档（{{reportInfo?.pageCount}}页）</span>
                            <el-divider border-style="dashed" style="margin:10px 0;" />
                            <span>报告字数：{{reportInfo?.wordsNum}}字</span>
                            <el-divider border-style="dashed"  style="margin:10px 0;" />
                            <template v-if='reportInfo?.style==1'>
                                <span>产业领域：{{reportInfo?.industryClassName}}</span>
                                <el-divider border-style="dashed"  style="margin:10px 0;" />
                            </template>
                            <span>报告价格：<span style="color:red;">{{reportInfo?.type==1?'免费':'￥'+reportInfo?.price}}</span></span>
                        </div>
                        <el-button v-if='reportInfo?.type==1' type="primary" round @click="scrollToAnchor">在线阅读</el-button>
                        <el-popover v-else placement="right" :width="270" trigger="hover">
                            <template #reference>
                                <el-button type="primary" round @click="buyDialogVisible=true">咨询购买</el-button>
                            </template>
                            <div style="text-align:center;margin:20px;">
                                <el-image class='pic-wechat' src="https://data.chinaecono.com/images/wx_code.jpg?t=1697007893" fit="fill" />
                                <br>
                                <span>微信扫一扫</span>
                            </div>
                        </el-popover>
                </el-col>
            </el-row>
        </div>
        <div  v-if='reportInfo?.type==1' style="background: #fff;padding:20px;">
            <!-- 摘要-->
            <div  v-if='reportInfo?.audioInfo' style="border:1px solid #ccc;border-radius:4px;margin-bottom:20px;">
                <span style="padding-top:20px;padding-left:20px;display: inline-block;font-weight: 700;">报告摘要</span>
                <v-md-preview :text="reportInfo?.audioInfo"></v-md-preview>
            </div>
             <!-- 在线阅读  -->
            <div ref="sectionRef">
                <span style="padding:20px;display: inline-block;font-weight: 700;">在线阅读</span>
                <iframe :src='`/static/pdf/web/viewer.html?file=${reportInfo?.documentPath}&page=1`' style="width:100%;height:800px;" ></iframe>
            </div>
        </div>
        <div v-else class="ant-row">
            <el-tabs v-model="tabsName" class="tabs-bar">
                <el-tab-pane label="报告目录" name="catalog">
                    <div>
                        <v-md-preview :text="reportInfo?.catalog"></v-md-preview>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="摘要" name="audioInfo">
                    <div>
                        <v-md-preview :text="reportInfo?.audioInfo"></v-md-preview>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="内容概况" name="content">
                    <el-scrollbar class="wrap">
                        <el-image v-for="item in reportImageList" :key="item" class="pic" style="width: 100%;" :src="item.picUrl" fit="fill" />
                        <div class="last">试读结束</div>
                    </el-scrollbar>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="padding10"></div>
</div>
</template>
<script>
import { ref } from 'vue'
import axios from 'axios';
import {getReportInfo,getReportContentList,getReportLabelList,getIndustryClassInfo} from '@/http/reportApi'
import { useRoute } from 'vue-router';
import {parseTime} from '@/utils/index'
import {addUserLog} from '@/http/basicsApi.js'
export default {
    setup() {
        let route=useRoute();
        let tabsName=ref('catalog');
        let reportInfo=ref();
        let buyDialogVisible=ref(false);
        //锚定
        const sectionRef = ref(null);
        const scrollToAnchor = () => {
            if (sectionRef.value) {
                sectionRef.value.scrollIntoView({ behavior: 'smooth' });
            }
        };
        //获取报告的详细信息
        const getReportInfoFun=async () =>{
            let res=await getReportInfo(route.query.id);
            if(res.code==20000){
                reportInfo.value=res.data;
                console.log(res.data);
                // let path=`http://data.chinaecono.com/report/${parseTime(new Date(reportInfo.value.date),'{y}')}/`;
                // reportInfo.value.pic=path+reportInfo.value.pic;
                if(reportInfo.value.documentType==1){
                    reportInfo.value.documentType='PDF';
                }
                else if(reportInfo.value.documentType==2){
                    reportInfo.value.documentType='WORD';
                }
                else if(reportInfo.value.documentType==3){
                    reportInfo.value.documentType='EXCEL';
                }
                else if(reportInfo.value.documentType==4){
                    reportInfo.value.documentType='PPT';
                }
                //reportInfo.value.catalog='https://data.chinaecono.com//report/2023/09/3f7bc03f80981e73a905d2897cc3139c/t20081128_2061612.md';
                axios.get(reportInfo.value.catalog).then((response) => {
                    reportInfo.value.catalog = response.data;
                });
                if(reportInfo.value.audioInfo){
                    axios.get(reportInfo.value.audioInfo).then((response)=>{
                        reportInfo.value.audioInfo=response.data;
                    })
                }
                
                //产业分类
                getIndustryClassInfoFun(reportInfo.value.industryClassId);
                //reportInfo.value.content=reportInfo.value.content.split(';').map(t=>path+t);
                //标签
                let res_=await getReportLabelList({objIds:route.query.id.toString(),pageIndex:1,pageSize:1});
                reportInfo.value.label=res_.data && res_.data.totalCount>0 ?res_.data.dataList.map(t=>t.labelName).join(','):'';
                //增加浏览量
                //updateViewCountOfReport({ids:route.query.id.toString()});
            }
        }
        //获取产业分类信息
        const getIndustryClassInfoFun=async(id)=>{
            let res=await getIndustryClassInfo(id);
            if(res.code==20000){
                reportInfo.value.industryClassName=res.data.name;
            }
        }
        //获取报告内容的图片链接
        let reportImageList=ref([]);
        const getReportContentListFun= async()=>{
            let params={
                ids:route.query.id.toString(),
                pageIndex:1,
                pageSize:3
            }
            let res=await getReportContentList(params);
            console.log(res);
            if(res.code==20000){
                reportImageList.value=res.data.dataList;
            }
            console.log(reportImageList.value);
        }
        //初始化
        getReportInfoFun();
        getReportContentListFun();
        //记录用户操作日志
        addUserLog(route.name,route.fullPath,'加载','{"id":'+route.query.id+'}');
        return{
            sectionRef,
            scrollToAnchor,
            tabsName,
            reportInfo,
            parseTime,
            reportImageList,
            buyDialogVisible
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.img-report-detail {
    border: 1px solid #999;
    border-radius: 6px;
    width:230px;
    height:320px;
    box-shadow:3px 3px 5px 0px rgba(0, 0, 0, 0.2);
}
.pic {
    margin-bottom: 5px;
    border: 1px solid $bordercolor;
    box-shadow: 3px 3px 10px 2px rgba($bordercolor, 0.5);
}
.wrap{
    height: 1200px;
    width:800px;
    margin:auto;
    padding:15px;
    border: 1px solid $bordercolor;
    box-shadow: inset 0 0 5px 2px rgb(0 0 0 / 50%);
}
.last {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}
.info-attr{
    color: rgb(157, 158, 159); 
    margin-top: 10px;
}
.info-desc{
    width: 600px; 
    font-size: 14px;
     background: rgb(249, 250, 251); 
     padding: 12px; 
     margin-top: 10px; 
     margin-bottom: 20px; 
     border-radius: 4px; 
     border: 1px dashed rgb(157, 158, 159);
}
.info-price{
    font-size: 20px;
    font-style: italic; 
    color: rgb(240, 69, 53);
}
.tabs-bar{
    ::v-deep .el-tabs__header .el-tabs__nav-wrap{
        background: $bordercolor;
        padding-left: 20px;
        height: 60px;
        display: flex;
        align-items: center;
        .el-tabs__item{
            height: 60px;
            line-height: 60px;
            font-size: 18px;
        }
    }
}
.pic-wechat{
    border:1px solid $bordercolor;
    width: 200px;
    height: 200px;
    box-sizing: content-box;
}
::v-deep  .github-markdown-body  p{
    text-indent: 2em;
}
</style>