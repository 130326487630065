//调用政策库的接口
import $http from './index'

//获取产业分类列表
export const getIndustryClassList=(data)=>{
  return $http.post(`/api/Report/GetIndustryClassList`,data);
}
//获取产业分类信息
export const getIndustryClassInfo=(id)=>{
    return $http.get(`/api/Report/GetIndustryClassInfo?id=${id}`);
}
//获取报告列表
export const getReportList=(data)=>{
    return $http.post(`/api/Report/GetReportList`,data);
}
//获取报告的详细信息
export const getReportInfo=(id)=>{
    return $http.get(`/api/Report/GetReportInfo?id=${id}`);
}
//获取报告内容的图片链接
export const getReportContentList=(data)=>{
    return $http.post(`/api/Report/GetReportContentList`,data);
}
//获取报告的标签列表
export const getReportLabelList=(data)=>{
    return $http.post(`/api/Report/GetReportLabelList`,data);
}
//更新报告的浏览次数
export const updateViewCountOfReport=(data)=>{
    return $http.post(`/api/Report/UpdateViewCountOfReport`,data);
}